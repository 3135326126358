var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invite-link-action-setting"},[(_vm.isVisible('approve'))?_c('select-input',{attrs:{"setup":{
      'func': _vm.approveSetter,
      'args': {
        'model': _vm.model,
        'key': 'approve',
        'prefix': 'invite_link_model_',
        'clearable': false,
        'options': _vm.approveTypeOptions,
        'translationParams': [ _vm.$store.state.boardsState.activeBoard.title ],
        'disabled': _vm.isReadonly('approve')
      }
    }}}):_vm._e(),(_vm.channelId && _vm.model.approve === _vm.InviteLinkApproveType.None)?_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('invite_link_approve_none_general_settings', [ _vm.$store.getters.activeBoard.board.split('.')[0], _vm.channelId ]))},slot:"message"})]):_vm._e(),_c('transition-group',{attrs:{"name":"default-hide","mode":"out-in"}},[(_vm.model.approve !== _vm.InviteLinkApproveType.None && _vm.isVisible('message'))?_c('multi-message-editor-with-media-input',{key:("message-" + _vm.template),attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'message',
          'availableButtonsTypes': _vm.autoApproveButtons,
          'prefix': 'invite_link_',
          'base-api-url': 'api.presscode.info/cabinet',
          'has-media': true,
          'buttonsHelpView': _vm.InviteLinkEditorMessage,
          'targetToUpload': {
            'target': 'target',
            'target_id': _vm.$store.state.boardsState.activeBoard.board
          },
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'validation': 'required',
          'settingsStructure': {
            'send_after': 0,
            'remove_previous': false,
            'pin': false,
            'disable_link_preview': false,
            'disable_notify': false,
          }
        }
      }}}):_vm._e(),(_vm.model.approve === _vm.InviteLinkApproveType.Auto && _vm.hasCaptchaButton  && _vm.isVisible('force_approve'))?_c('switch-input',{key:"force_approve",staticClass:"w-full",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'force_approve',
        }
      }}}):_vm._e(),(_vm.model.approve !== _vm.InviteLinkApproveType.None)?_c('div',{key:"actions",staticClass:"flex flex-col xl:flex-row gap-5 w-full",class:{
        'xl:flex-col': _vm.hasCaptchaButton && _vm.isManualApprove
      }},[(_vm.hasCaptchaButton && _vm.isVisible('user_captcha_success_action'))?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",class:{
          'xl:w-full': _vm.hasCaptchaButton && _vm.isManualApprove
        },attrs:{"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.info/thumbnail/flow/" + key); },"empty-flow-button-callback":_vm.gotoFlows,"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"model-path":"user_captcha_success_action","prefix":_vm.isAutoApprove ? 'auto_' : _vm.isManualApprove ? 'manual_' : '',"limit-types":_vm.limitTypes},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('a-alert',{attrs:{"slot":"no-action-info","show-icon":""},slot:"no-action-info",scopedSlots:_vm._u([{key:"message",fn:function(){return [(_vm.isAutoApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_captcha_success_action_none_auto_approve'))}}):_vm._e(),(_vm.isManualApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_captcha_success_action_none_manual_approve'))}}):_vm._e()]},proxy:true}],null,false,3962521458)})],1):_vm._e(),_c('div',{staticClass:"flex flex-col xl:flex-row gap-5 w-full",class:{
          'w-full xl:w-1/2': _vm.hasCaptchaButton && _vm.isAutoApprove
        }},[(_vm.isVisible('user_accept_action') && _vm.model.approve === _vm.InviteLinkApproveType.Manual || (_vm.model.approve === _vm.InviteLinkApproveType.Auto && _vm.hasCaptchaButton))?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",class:{
            'xl:w-full': _vm.hasCaptchaButton && _vm.isAutoApprove
          },attrs:{"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.info/thumbnail/flow/" + key); },"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"empty-flow-button-callback":_vm.gotoFlows,"model-path":"user_accept_action","limit-types":_vm.limitTypes},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('a-alert',{attrs:{"slot":"no-action-info","show-icon":""},slot:"no-action-info",scopedSlots:_vm._u([{key:"message",fn:function(){return [(_vm.isManualApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_accept_action_select_none_action_manual_approve'))}}):_vm._e(),(_vm.isAutoApprove)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('user_accept_action_select_none_action_auto_approve'))}}):_vm._e()]},proxy:true}],null,false,1298198098)})],1):_vm._e(),(_vm.model.approve === _vm.InviteLinkApproveType.Manual && _vm.isVisible('user_reject_action'))?_c('activation-action-data',{staticClass:"w-full xl:w-1/2",attrs:{"flow-options":_vm.resolveFlowSelectOption,"get-flow-preview":function (key) { return ("https://api.presscode.info/thumbnail/flow/" + key); },"board":_vm.$store.state.boardsState.activeBoard,"buttons":_vm.newPmButtons,"editor-buttons-help-view":_vm.EditorButtonsHelpView,"empty-flow-button-callback":_vm.gotoFlows,"model-path":"user_reject_action","limit-types":_vm.limitTypes},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }